import request from '@/utils/request'

// 考试列表
export function getList(params) {
  return request({
    url: '/api/exams',
    method: 'get',
    params,
  })
}

// 考试修改邀请码
export function examsInviteCode(id, inviteCode) {
  return request({
    url: `/api/exams/${id}/inviteCode`,
    method: 'put',
    data: {
      "data": {
        inviteCode
      }
    }
  });
}

// 考试修改状态
export function examsStatus(id, status) {
  return request({
    url: `/api/exams/${id}/status`,
    method: 'put',
    data: {
      "data": {
        status
      }
    }
  });
}

// 试卷列表
export function getExamPapers(params) {
  return request({
    url: '/api/exam-papers',
    method: 'get',
    params,
  })
}

// 试卷详情
export function getExamPapersDetail(id) {
  return request({
    url: `/api/exam-papers/${id}`,
    method: 'get',
  })
}

// 事件统计
export function eventsStatistics(params) {
  return request({
    url: `/api/events/statistics`,
    method: 'get',
    params,
  })
}

// 事件统计详情
export function eventsStatisticsDetail(params) {
  return request({
    url: `/api/events`,
    method: 'get',
    params,
  })
}

// 导出作答
export function exportAnswer(id) {
  return request({
    url: `/api/exams/${id}/export-answer`,
    method: 'get'
  })
}

// 导出事件
export function exportEvent(id) {
  return request({
    url: `/api/exams/${id}/export-event`,
    method: 'get'
  })
}
