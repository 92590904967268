<template>
  <div class="column-flex">
    <div class="search-plate">
      <template v-if="paperList[0]?.attributes?.exam?.data?.attributes?.status == 0">
        <span>答题题目：{{paperList[0]?.attributes?.exam?.data?.attributes?.title}}</span>
        <span>题目数量：{{paperList[0]?.attributes?.exam?.data?.attributes?.questionCount}}</span>
        <span>未开始</span>
      </template>
      <template v-else-if="paperList[0]?.attributes?.exam?.data?.attributes?.status == 1">
        <span>答题题目：{{paperList[0]?.attributes?.exam?.data?.attributes?.title}}</span>
        <span>题目数量：{{paperList[0]?.attributes?.exam?.data?.attributes?.questionCount}}</span>
        <span>答题开始时间：{{getTime(paperList[0]?.attributes?.exam?.data?.attributes?.startTime)}}</span>
        <span>进行中</span>
      </template>
      <template v-else>
        <span>答题题目：{{paperList[0]?.attributes?.exam?.data?.attributes?.title}}</span>
        <span>题目数量：{{paperList[0]?.attributes?.exam?.data?.attributes?.questionCount}}</span>
        <span>答题开始时间：{{getTime(paperList[0]?.attributes?.exam?.data?.attributes?.startTime)}}</span>
        <span>答题结束时间：{{getTime(paperList[0]?.attributes?.exam?.data?.attributes?.endTime)}}</span>
        <span>已完成</span>
      </template>
    </div>
    <div class="container">
      <a-table :columns="columns" :row-key="(record) => record.uuid" :data-source="paperList" :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'realname'">
            {{record?.attributes?.doctor?.data?.attributes?.realname}}
          </template>
          <template v-if="column.key === 'phone'">
            {{maskPhoneNumber(record?.attributes?.doctor?.data?.attributes?.phone)}}
          </template>
          <template v-if="column.key === 'hospital'">
            {{record?.attributes?.doctor?.data?.attributes?.hospital}}
          </template>
          <template v-if="column.key === 'workingAge'">
            {{record?.attributes?.doctor?.data?.attributes?.workingAge}}
          </template>
          <template v-if="column.key === 'titleLevel'">
            {{getTitleName(record?.attributes?.doctor?.data?.attributes?.titleLevel)}}
          </template>
          <template v-if="column.key === 'mode'">
            {{getMode(record?.attributes?.doctor?.data?.attributes?.mode)}}
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a @click="showModal(record.id)">答题详情</a>
            </span>
          </template>
        </template>
      </a-table>
      <a-modal v-model:visible="visible" @ok="handleOk" width="1400px">
        <template #title>
          <div ref="modalTitleRef" style="width: 100%; cursor: move">用户答题详情</div>
        </template>
        <a-form :model="formState" :label-wrap="true" label-align='left'>
          <a-form-item label="昵称" name="realname">
            <a-input v-model:value="formState.realname" disabled />
          </a-form-item>
          <a-form-item label="手机号码" name="phone">
            <a-input :value="maskPhoneNumber(formState.phone)" disabled />
          </a-form-item>
          <a-form-item label="医院名称" name="hospital">
            <a-input v-model:value="formState.hospital" disabled />
          </a-form-item>
          <a-form-item label="年资" name="workingAge">
            <a-input v-model:value="formState.workingAge" disabled />
          </a-form-item>
          <a-form-item label="职称" name="titleLevel">
            <a-input :value="getTitleName(formState.titleLevel)" disabled />
          </a-form-item>
          <a-form-item label="答题开始时间" name="startTime">
            <a-input v-model:value="formState.startTime" disabled />
          </a-form-item>
          <a-form-item label="答题结束时间" name="endTime">
            <a-input v-model:value="formState.endTime" disabled />
          </a-form-item>
          <!-- <a-form-item label="全过程答题使用时间" name="examDuration">
            <a-input v-model:value="formState.examDuration" disabled />
          </a-form-item> -->
          <a-form-item label="答题持续时间" name="durationTime">
            <a-input v-model:value="formState.durationTime" disabled />
          </a-form-item>
        </a-form>
        <a-table :columns="datailColumns" :row-key="(record) => record.uuid" :data-source="datailList"
          :pagination="false" :loading="loading" @change="handleTableChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'firstAnswerTime'">
              {{getTime(record?.answer?.firstAnswerTime)}}
            </template>
            <template v-if="column.key === 'riskFirst'">
              {{record?.answer?.riskFirst == 1?'高风险':record?.answer?.riskFirst == 0?'低风险':''}}
            </template>
            <template v-if="column.key === 'reliabilityFirst'">
              {{getReliabilityName(record?.answer?.reliabilityFirst)}}
            </template>
            <template v-if="column.key === 'riskSecond'">
              {{record?.answer?.riskSecond == 1?'高风险':record?.answer?.riskSecond == 0?'低风险':''}}
            </template>
            <template v-if="column.key === 'reliabilitySecond'">
              {{getReliabilityName(record?.answer?.reliabilitySecond)}}
            </template>
            <template v-if="column.key === 'mode'">
              {{getMode(formState.mode)}}
            </template>
            <template v-if="column.key === 'level'">
              {{getLevel(record?.level)}}
            </template>
            <!-- 患者相关信息 -->
            <template v-if="column.key === 'relatedInfo'">
              <span v-show="false">{{record.showDetail}}</span>
              <a @mouseover="changeDetail(record,true)" @mouseleave="changeDetail(record,false)">查看</a>
              <div v-if="record.showDetail" class="detail-form">
                <a-form :model="record" :label-wrap="true" label-align='left'>
                  <a-form-item label="急诊分诊时刻（小时）" name="edHour">
                    <a-input :value="record.edHour+'点'" />
                  </a-form-item>
                  <a-form-item label="年龄" name="age">
                    <a-input v-model:value="record.age" />
                  </a-form-item>
                  <a-form-item label="性别" name="gender">
                    <a-input :value="record.gender==1?'男':record.gender==0?'女':''" />
                  </a-form-item>
                  <a-form-item label="抵达方式" name="arrivalTransport">
                    <a-input :value="getArrivalTransport(record.arrivalTransport)" />
                  </a-form-item>
                  <a-form-item label="体温（℃）" name="temperature">
                    <a-input :value="fahrenheitToCelsius(record.temperature)" />
                  </a-form-item>
                  <a-form-item label="心率（bpm）" name="heartrate">
                    <a-input v-model:value="record.heartrate" />
                  </a-form-item>
                  <a-form-item label="呼吸率（次/分）" name="resprate">
                    <a-input v-model:value="record.resprate" />
                  </a-form-item>
                  <a-form-item label="血氧饱和度" name="o2sat">
                    <a-input v-model:value="record.o2sat" />
                  </a-form-item>
                  <a-form-item label="收缩压（mmHg）" name="sbp">
                    <a-input v-model:value="record.sbp" />
                  </a-form-item>
                  <a-form-item label="舒张压（mmHg）" name="dbp">
                    <a-input v-model:value="record.dbp" />
                  </a-form-item>
                  <a-form-item label="平均动脉压（mmHg）" name="map">
                    <a-input :value="Number(record.map).toFixed(2)" />
                  </a-form-item>
                  <a-form-item label="休克指数" name="shockIndex">
                    <a-input :value="Number(record.shockIndex).toFixed(2)" />
                  </a-form-item>
                  <a-form-item label="ESI评级" name="acuity">
                    <a-input v-model:value="record.acuity" />
                  </a-form-item>
                </a-form>
              </div>
            </template>

            <template v-if="column.key === 'status'">
              <span class="dot"
                :style="'background:'+(record.status==1?'#1890FF':record.status==2?'#5FDDC3':'rgba(0, 0, 0, 0.25)')"></span>
              {{ record.status == 0 ?'未开始':record.status == 1 ?'进行中':'已完成'}}
            </template>
          </template>
        </a-table>
        <template #footer>
          <a-button key="back" @click="handleOk">关闭</a-button>
        </template>
      </a-modal>
    </div>
  </div>

</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue'
  import { getExamPapers, getExamPapersDetail } from '@/api/answer'
  import { Modal } from 'ant-design-vue';
  import { useRoute } from 'vue-router';
  const route = useRoute();

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
    },
    {
      title: '昵称',
      dataIndex: 'realname',
      key: 'realname',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '医院名称',
      dataIndex: 'hospital',
      key: 'hospital'
    },
    {
      title: '年资',
      dataIndex: 'workingAge',
      key: 'workingAge'
    },
    {
      title: '职称',
      key: 'titleLevel',
      dataIndex: 'titleLevel', //  1：医学生&住院医、2：主治医、3：副高级、4：正高级
    },
    {
      title: '交互模式类别',
      dataIndex: 'mode',
      key: 'mode',
    },
    {
      title: '答题开始时间',
      dataIndex: 'startTime',
    },
    {
      title: '答题结束时间',
      dataIndex: 'endTime',
    },
    {
      title: '答题使用时间',
      dataIndex: 'examDuration',
    },
    {
      title: '操作',
      key: 'action',
    },
  ]
  const datailColumns = [
    {
      title: '题号',
      dataIndex: 'index',
    },
    {
      title: '患者相关信息',
      dataIndex: 'relatedInfo',
      key: 'relatedInfo',
      width: 120
    },
    {
      title: '交互模式类别',
      dataIndex: 'mode',
      key: 'mode',
      width: 140
    },
    {
      title: '答题时间',
      dataIndex: 'firstAnswerTime',
      key: 'firstAnswerTime',
      width: 180
    },
    {
      title: '答题时长',
      dataIndex: 'durationTime',
      width: 180
    },
    {
      title: '题目难度',
      dataIndex: 'level',
      key: 'level',
      width: 150
    },
    {
      title: '初始评估风险',
      dataIndex: 'riskFirst',
      key: 'riskFirst',
      width: 120
    },
    {
      title: '确信程度',
      key: 'reliabilityFirst',
      dataIndex: 'reliabilityFirst',
      width: 100
    },
    {
      title: '最终评估风险',
      dataIndex: 'riskSecond',
      key: 'riskSecond',
      width: 120
    },
    {
      title: '最终确信程度',
      dataIndex: 'reliabilitySecond',
      key: 'reliabilitySecond',
      width: 120
    },

  ]

  const formState = ref({})
  const visible = ref(false)

  function maskPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const maskedNumber = phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
      return maskedNumber;
    } else {
      return ''
    }
  }

  const getMode = (mode) => {
    switch (mode) {
      case 1:
        return 'info少，cost少';
        break;
      case 2:
        return 'info少，cost多';
        break;
      case 3:
        return 'info多，cost多';
        break;
      case 4:
        return 'info多，cost少';
        break;
      default:
        return '';
    }
  }

  //1-10行 有风险且确信度高、 11-20行 有风险但确信度低、21-30行 无风险且确信度高、31-40行 无风险但确信度低
  const getLevel = (level) => {
    switch (level) {
      case 1:
        return '有风险且确信度高';
        break;
      case 2:
        return '有风险但确信度低';
        break;
      case 3:
        return '无风险且确信度高';
        break;
      case 4:
        return '无风险但确信度低';
        break;
      default:
        return '';
    }
  }


  // 确信程度 0:低 1：中 2：高
  const getReliabilityName = (level) => {
    switch (level) {
      case 0:
        return '低';
        break;
      case 1:
        return '中';
        break;
      case 2:
        return '高';
        break;
      default:
        return '';
    }
  }


  const getTitleName = (level) => {
    switch (level) {
      case 1:
        return '医学生&住院医';
        break;
      case 2:
        return '主治医';
        break;
      case 3:
        return '副高级';
        break;
      case 4:
        return '正高级';
        break;
      default:
        return '';
    }
  }

  // 华氏度转摄氏度
  function fahrenheitToCelsius(fahrenheit) {
    if (fahrenheit > 0) {
      return parseInt((fahrenheit - 32) * 5 / 9) * 100 / 100 + '°C'
    } else {
      return ''
    }
  }

  // 救护车为0，1 直升机 ，2其他  自行为3
  const getArrivalTransport = (e) => {
    switch (e) {
      case 0:
        return '救护车';
        break;
      case 1:
        return '直升机';
        break;
      case 2:
        return '其他';
        break;
      case 3:
        return '自行';
        break;
      default:
        return '';
    }
  }

  function changeDetail(record, type) {
    record.showDetail = type
  }

  // 当前时间
  function getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  }

  // 计算时间差
  function getDurationTime(startTime, endTime, isSecond) {
    if (!startTime && !endTime) {
      return ''
    } else {
      if (!endTime) {
        // 没有endTime，按当前时间计算
        endTime = getCurrentTime()
      }

      // 计算时间差（单位为毫秒）
      const diffInMs = Math.abs(new Date(endTime) - new Date(startTime));
      // 将时间差转换为天、小时和分钟
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
      if (isSecond) {
        const seconds = Math.floor((diffInMs % (1000 * 60)) / (1000));
        return `${days}天 ${hours}小时 ${minutes}分钟 ${seconds}秒`
      } else {
        return `${days}天 ${hours}小时 ${minutes}分钟`
      }
    }
  }

  // 时间差
  const getDuration = (diffInMs) => {
    if (diffInMs) {
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

      return `${days}天 ${hours}小时 ${minutes}分钟`
    } else {
      return ''
    }
  }

  const getTime = (time) => {
    if (time) {
      const convertedTime = new Date(time);
      convertedTime.setHours(convertedTime.getHours() + 8);
      const formattedTime = convertedTime.toLocaleString('zh-CN', { timeZone: 'UTC' });
      return formattedTime;
    } else {
      return ''
    }
  }

  const showModal = (id) => {
    visible.value = true;

    // 获取详情
    getExamPapersDetail(id).then((res) => {
      if (res?.data?.attributes?.doctor?.data?.attributes) {
        formState.value = res.data.attributes.doctor.data.attributes;
      }
      if (res?.data?.attributes) {
        formState.value.startTime = res.data.attributes.startTime ? getTime(res.data.attributes.startTime) : '';
        formState.value.endTime = res.data.attributes.endTime ? getTime(res.data.attributes.endTime) : res.data.attributes.exam.data.attributes.endTime ? getTime(res.data.attributes.exam.data.attributes.endTime) : '';
        formState.value.examDuration = res.data.attributes.examDuration ? getDuration(res.data.attributes.examDuration) : '';
        formState.value.durationTime = getDurationTime(formState.value.startTime, formState.value.endTime);

        res.data.attributes.questions.forEach((item, index) => {
          item.index = index + 1;
          item.showDetail = false;
          item.durationTime = getDurationTime(item?.answer?.firstAnswerTime, item?.answer?.endTime, true);
        });
        datailList.value = res.data.attributes.questions
      }
    })
  };

  const handleOk = (e) => {
    visible.value = false;
  };

  const paperList = ref([])
  const datailList = ref([])

  const pagination = reactive({
    showLessItems: true,
    showQuickJumper: true,
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0
  })
  const loading = ref(false)

  function handleTableChange(p) {
    pagination.current = p.current
    pagination.pageSize = p.pageSize
    fetch();
  }

  function fetch() {
    loading.value = true
    getExamPapers({
      exam: route.query.id,
      _pageSize: pagination.pageSize,
      _page: pagination.current,
    }).then((res) => {
      loading.value = false
      res.data.forEach((item, index) => {
        item.index = (pagination.current - 1) * pagination.pageSize + index + 1
        item.startTime = item.attributes.startTime ? getTime(item.attributes.startTime) : '';
        if (!!item.attributes.endTime) {
          item.endTime = getTime(item.attributes.endTime);
        } else if (item.attributes.exam.data.attributes.endTime) {
          item.endTime = getTime(item.attributes.exam.data.attributes.endTime);
        }
        item.examDuration = getDurationTime(item.attributes.startTime, item.endTime);
      });
      paperList.value = res.data
      pagination.total = res.meta.total
    })
  }

  onMounted(() => {
    fetch()
  })
</script>

<style lang="less" scoped>
  .column-flex {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .container {
      flex: 1;
    }
  }

  .search-plate {
    background: #fff;
    padding: 22px 30px;
    margin-bottom: 15px;
    border-radius: 5px;

    span {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      margin-right: 60px;
    }
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }

  .ant-form {
    display: flex;
    flex-flow: row wrap;

    :deep .ant-form-item {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 35px 12px 0;
      height: 64px;

      .ant-input {
        border-color: #EBEDF0;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #555770;
      }
    }
  }

  :deep .ant-form-item-label>label {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: rgba(77, 96, 134, 0.85);
  }

  .detail-form {
    position: fixed;
    left: calc((100vw - 1200px) / 2 + 180px);
    top: calc(35vh);
    width: 800px;
    height: 405px;
    padding: 13px 25px 25px;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    z-index: 9;
  }
</style>